// this is meant to be run in the browser console
jQuery(function() {
  var $rows = jQuery('body.wpml_page_wpml-translation-management-menu-main #icl_cf_translation table tbody tr');
  if($rows.length) {
    $rows.each(function(i,el) {
      var $row = jQuery(el);
      var field_name = $row.find('td').first().text();
      if(field_name.match(/copy$/) || field_name.match(/image_alt$/)) {
        $row.find('td').eq(4).find('input').attr('checked', true);
      } else {
        $row.find('td').eq(2).find('input').attr('checked', true);
      }
    });
    console.log('WPML configuration script ran.');
  }
});
