var DS = window.DS || {};
DS.destinationFilters = function() {
  var els = {};

  var listen = function() {
    els.heading.on('click',switchTabs);
    els.destinationListTrigger.on('click',showDestinationList);
    els.tabTerms.children('div').on('click', showContentBlocks);
    els.tabHeadingMobile.on('click', mobileContentTrigger);
  };

  var switchTabs = function() {
    var $self = $(this);
    els.heading.not($self).removeClass('active');
    $self.addClass('active');
    if($self.hasClass('right')) {
      showTabContent('right');
    } else {
      showTabContent('left');
    }
  };

  var showTabContent = function(elClass) {
    els.tabTerms.not($(this)).removeClass('open');
    els.tabTerms.filter('.' + elClass).addClass('open');
  };

  var showDestinationList = function() {
    els.destinationList.toggleClass('open');
  };

  var showContentBlocks = function() {
    var $self = $(this);
    els.termComponents.removeClass('active');
    els.tabTerms.children('div').removeClass('active');
    $self.addClass('active');
    var term = $self.attr('data-term');
    if($self.parent('.right').length) {
      openRightContentBlock(term);
      els.heading.filter('.right').find('.tab-heading-selection-mobile').html(term.replace('_', ' ') + '<span class="icon-carret"></span>');
    } else {
      openLeftContentBlock(term);
      els.heading.filter('.left').find('.tab-heading-selection-mobile').html(term.replace('_', ' ') + '<span class="icon-carret"></span>');
    }
    if(window.app.isMobile()) {
      els.tabTerms.removeClass('open')
      closeAllTabTerms();
    }
  };

  var openRightContentBlock = function(term) {
    var $container = els.rightTab.filter('[data-term="' + term + '"]');
    $container.addClass('active');
    $container.find('.grid-component').isotope({
      itemSelector: '.grid-block-component',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer'
      },
      transitionDuration: 0
    });
  };

  var openLeftContentBlock = function(term) {
    var $container = els.leftTab.filter('[data-term="' + term + '"]');
    $container.addClass('active');
    $container.find('.grid-component').isotope({
      itemSelector: '.grid-block-component',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer'
      },
      transitionDuration: 0
    });
  };

  var mobileContentTrigger = function() {
    var $self = $(this);
    els.tabHeadingMobile.not($self).removeClass('open');
    $self.addClass('open');
    if ($self.parent().hasClass('left')) {
      els.tabTerms.filter('.right').removeClass('open');
      els.tabTerms.filter('.left').addClass('open');
      els.heading.filter('.right').addClass('inactive');
      els.heading.filter('.left').removeClass('inactive');
    } else {
      els.tabTerms.filter('.left').removeClass('open');
      els.tabTerms.filter('.right').addClass('open');
      els.heading.filter('.right').removeClass('inactive');
      els.heading.filter('.left').addClass('inactive');
    }
  };

  var closeAllTabTerms = function() {
    els.tabTerms.removeClass('open');
    els.heading.removeClass('inactive');
    els.tabHeadingMobile.removeClass('open');
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        heading: $el.find('.tab-heading'),
        destinationListTrigger : $el.find('.destinations-list-trigger'),
        destinationList : $el.find('.destination-list'),
        tabTerms : $el.find('.tab-terms'),
        rightTab : $el.next('.term-component-container').find('.right-tab'),
        leftTab : $el.next('.term-component-container').find('.left-tab'),
        termComponents : $el.next('.term-component-container').find('.term-components'),
        tabHeadingMobile : $el.find('.tab-heading-selection-mobile')
      };
      listen();
      if(window.app.isMobile()) {
        els.tabTerms.filter('.left.open').removeClass('open');
      }
    }
  };
};

$(function() {
  var $components = $('.destination-filters');
  if($components.length) {
    $components.each(function(i,el) {
      var destinationFilters = new DS.destinationFilters();
      destinationFilters.init(el);
    });
  }
});
