var DS = window.DS || {};
DS.verticalInstagramGrid = function (el) {
  var els = {};

  var init = function () {
    els.component = $(el);
    els.blockList = $('.vertical-instagram-grid-block-list');

    els.blockList.isotope({
      itemSelector: '.vertical-instagram-grid-block',
      layoutMode: 'spineAlign',
      transitionDuration: 0
    });

    els.blockList.imagesLoaded().progress(function () {
      els.blockList.isotope();
    });
  };

  init();
};

$(function () {
  if (!$('.vertical-instagram-grid-component').length) return;
  $('.vertical-instagram-grid-component').each(function (i, el) {
    new DS.verticalInstagramGrid(el);
  });
});
