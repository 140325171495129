var DS = window.DS || {};
DS.carousel = function() {
  var els = {};

  var listen = function() {
    els.slideHeadings.on('focus', goToSlideForHeading);
  };

  var goToSlideForHeading = function(e) {
    var $target = $(e.currentTarget);
    var slideIndex = $target.closest('.slide').index();
    var currentIndex = els.slides.slick('slickCurrentSlide');
    if(currentIndex !== slideIndex) {
      els.slides.slick('slickGoTo',slideIndex);
    }
  };

  var buildCarousel = function() {
    var initAutoplayVideos = function() {
      if(!window.app.isMobile()) {
        var $firstSlide = els.slides.find('.slide').first();
        if($firstSlide.find('.background').hasClass('show-video')) {
          videojs($firstSlide.find('.video-js').attr('id')).play();
        }
      }
    };

    var beforeChangeAction = function(currentSlide, nextSlide) {
      if(!window.app.isMobile()) {
        var $currSlide = els.slides.find('.slide[data-slick-index="' + currentSlide.toString() + '"]');
        var $nextSlide = els.slides.find('.slide[data-slick-index="' + nextSlide.toString() + '"]');
        if($currSlide.find('.background').hasClass('show-video')) {
          videojs($currSlide.find('.video-js').attr('id')).pause();
        }
        if($nextSlide.find('.background').hasClass('show-video')) {
          videojs($nextSlide.find('.video-js').attr('id')).play();
        }
      }
    };

    var slickOptions = {
      arrows:true,
      infinite:false,
      nextArrow: '<div class="carousel-arrow carousel-arrow-next icon-carret"></div>',
      prevArrow: '<div class="carousel-arrow carousel-arrow-prev icon-carret"></div>',
      dots: true,
      slidesToShow:1,
      slidesToScroll:1,
      waitForAnimate:false,
      slide: '.slide',
      asNavFor: (els.slideAccessory.length ? ('#' + els.slideAccessory.attr('id')) : null),
      accessibility: false,
      responsive: [
        {
           breakpoint: 768,
           settings: {
            appendArrows: els.counterContainer

           }
        }
      ]
    };

    //Multiblock Carousel Options

    if(els.component[0].classList.contains('multi-block')) {
      slickOptions.slidesToShow = parseFloat($(els.component[0]).attr('data-slides'));
      slickOptions.responsive[0].settings.slidesToShow = 1;
      slickOptions.responsive[0].settings.centerMode = true;
      slickOptions.responsive[0].settings.centerPadding = '80px';
      //todo - move this to global - has to fire after slick init
      setTimeout(function(){
        $(els.component[0]).find('.svg-load').each(function(){
          var _this = $(this);
              svgURL = _this.attr('data-svg');
              //console.log(svgURL);
              $.get(svgURL, function(data) {
                $("svg", data).prependTo(_this);
              });
        });

      }, 0);

    }

    //hide dots if single slide
    if(els.slides.find('.slide').length<2){
      slickOptions.dots = false;
      els.counterContainer.remove();

    }

    els.slides.slick(slickOptions);

    els.slides.on({
      beforeChange: function(e, slick, currentSlide, nextSlide) {
        beforeChangeAction(currentSlide, nextSlide);
      }
    });

    initAutoplayVideos();
    if(els.slideAccessory.length) {
      buildAccessoryCarousel();
    }

    els.counter.text('1/' + els.slideElements.length);
  };

  var buildAccessoryCarousel = function() {
    els.slideAccessory.slick({
      arrows:false,
      infinite:false,
      dots:false,
      waitForAnimate:false,
      fade:true,
      touchMove: false,
      swipe: false,
      accessibility: false
    });
  };

  var updateSlideCount = function() {

    els.slides.on('init reInit afterChange', function(event,slick, currentSlide, nextSlide) {

      var i = (currentSlide ? currentSlide : 0) + 1;
      els.counter.text(i + '/' + slick.slideCount);

    });

  };

  return {
    init: function(el,i) {
      var $el = $(el);
      els = {
        component: $el,
        slides : $el.find('.slides'),
        slideElements : $el.find('.slide'),
        counter : $el.find('.slide-count'),
        counterContainer: $el.find('.slide-count-container'),
        slideAccessory : $el.find('.slide-accessory'),
        slideHeadings: $el.find('.slide').find('h1,h2,h3,h4,h5,h6,h7')
      };
      if(els.slideAccessory.length) {
        els.slideAccessory.attr('id','slide-accessory-' + i);
      }
      updateSlideCount();
      buildCarousel();
      listen();
    }
  };
};

$(function() {
  if($('.carousel-component').length) {
    $('.carousel-component').each(function(i,el) {
      var carousel = new DS.carousel();
      carousel.init(el,i);
    });
  }
});
