var DS = window.DS || {};
DS.header = function() {
  var els = {};

  var listen = function() {
    els.parentMenu.on({
      click: function (e) {
        if(window.app.isMobile()){
          var $target = $(e.currentTarget);
          if($target.hasClass('expanded')){
            $target.removeClass('expanded');
            els.menuItems.removeClass('hidden');
          } else {
            els.menuItems.addClass('hidden');
            $target.removeClass('hidden');
            $target.addClass('expanded');
          }
        }
      }
    });
    els.parentMenu.on({
      mouseenter: function (e) {
        if(!window.app.isMobile()){
          els.headerComponent.addClass('open');
        }
      },
      mouseleave: function (e) {
        if(!window.app.isMobile()){
          els.headerComponent.removeClass('open');
        }
      }
    });
    els.mobileNavButton.on({
      click: function (e) {
        els.mobileNavButton.toggleClass('is-active');
        els.headerComponent.toggleClass('mobile-nav-open');
        els.html.toggleClass('no-scroll');
      }
    });
    els.languageSelector.on({
      mouseenter: function (e) {
        if(!window.app.isMobile()){
          els.languageSelector.addClass('open');
          els.headerComponent.addClass('open');
        }
      },
      mouseleave: function (e) {
        if(!window.app.isMobile()){
          els.languageSelector.removeClass('open');
          els.headerComponent.removeClass('open');
        }
      }

    });
    els.languageSelector.find('.current-language').on({
      click: function (e) {
        if(window.app.isMobile()){
          els.languageSelector.addClass('open');
        }
      }
    });
    els.languageSelector.find('.language-close').on({
      click: function (e) {
        if(window.app.isMobile()){
          els.languageSelector.removeClass('open');
        }
      }
    });
    els.parentMenuLink.on({
      focus: function(e) {
        var $target = $(e.currentTarget);
        $target.parent().find('ul').show();
        if(!window.app.isMobile()){
          els.headerComponent.addClass('open');
        }
      }
    });
    els.parentMenu.find('ul li:last-child a').on({
      blur: function(e) {
        var $target = $(e.currentTarget);
        $target.closest('ul').hide();
        if(!window.app.isMobile()){
          els.headerComponent.removeClass('open');
        }
      }
    });
    els.currentLanguage.on({
      focus: function() {
        if(!window.app.isMobile()) {
          els.languageSelector.addClass('open');
        }
      }
    });

    els.languages.last().on({
      blur: function() {
        if(!window.app.isMobile()) {
          els.languageSelector.removeClass('open');
        }
      }
    });
  };

  return {
    init: function() {
      els = {
        window: $(window),
        html: $('html'),
        menuItems: $('.nav-menu > li'),
        parentMenu: $('.menu-item-has-children'),
        parentMenuLink: $('.menu-item-has-children > a'),
        mobileNavButton: $('button.hamburger'),
        languageSelector: $('.language-selector'),
        headerComponent: $('.header-component'),
        currentLanguage: $('.header-component .current-language'),
        languages: $('.header-component .language-list li a')
      };
      listen();
    }
  };
};

$(function() {
  var header = new DS.header();
  header.init();
});
