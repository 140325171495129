var DS = window.DS || {};
DS.tabs = function() {
  var els = {};
  var vars = {};

  var buildTabContent = function() {
    els.slides.slick({
      arrows: false,
      infinite: false,
      fade: true,
      dots: false,
      slidesToShow: 1,
      waitForAnimate: false,
      touchMove: false,
      swipe: false,
      accessibility: false
    });
  };

  var buildTabNavigationControls = function() {
    els.labels.off().on({
      click: function() {
        var $label = $(this);
        if(!$label.hasClass('active')) {
          var index = $label.attr('data-tab-index');
          els.labels.removeClass('active');
          $label.addClass('active');
          els.slides.slick('slickGoTo',index);
        }
      },
      keydown: function(e) {
        if(e.keyCode === 13) {
          var $label = $(this);
          if(!$label.hasClass('active')) {
            var index = $label.attr('data-tab-index');
            els.labels.removeClass('active');
            $label.addClass('active');
            els.slides.slick('slickGoTo',index);
          }
        }
      }
    });
  };

  var destroyTabCarousel = function() {
    els.slides.slick('unslick');
  }

  var openPanels = function() {
    els.labelMobile.off().on('click', function() {
      var $mobilePanelContainer = $(this).parents('.tab-content-panels-container-mobile');
      if($mobilePanelContainer.hasClass('open')) {
        $mobilePanelContainer.removeClass('open')
      } else {
        els.mobilePanelContainers.removeClass('open');
        $mobilePanelContainer.addClass('open');
      }
    });
  }

  var resizeTabs = function() {
    window.addEventListener('resize', function () {
      if(window.innerWidth < 769) {
        if(els.slides.hasClass('slick-initialized')) {
          clearTimeout(vars.adjustTabsComponentMobile);
          vars.adjustTabsComponentMobile = setTimeout(function() {
            destroyTabCarousel();
            openPanels();
          },100);
        }
      } else {
        if(!els.slides.hasClass('slick-initialized')) {
          clearTimeout(vars.adjustTabsComponentDesktop);
          vars.adjustTabsComponentDesktop = setTimeout(function() {
            buildTabContent();
            buildTabNavigationControls();
            els.mobilePanelContainers.removeClass('open');
          },100);
        }
      }
    });
  }

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        slides : $el.find('.tab-slide-wrapper'),
        slide : $el.find('.tab-slide'),
        labels : $el.find('.tab-label'),
        labelMobile : $el.find('.tab-label-mobile'),
        mobilePanelContainers: $el.find('.tab-content-panels-container-mobile')
      };
      vars = {
        adjustTabsComponentMobile: null,
        adjustTabsComponentDesktop: null
      };
      if (window.innerWidth > 768) {
        buildTabContent();
        buildTabNavigationControls();
      } else {
        openPanels();
      }
      resizeTabs();
    }
  };
};

$(function() {
  $('.tabs-component').each(function(i,el) {
    var tabs = new DS.tabs();
    tabs.init(el);
  });
});
