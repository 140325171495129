var DS = window.DS || {};
DS.directory = function(){
  var els = {};
  var vars = {};
  var data;

  var listen = function(){
    $(window).on('googleMapsReady', initMap);
    els.regionSelect.on('change', regionSelectChange);
    els.list.on('click', 'a[data-type="region"]', regionListClick);
    els.countrySelect.on('change', countrySelectChange);
    els.list.on('click', 'a[data-type="country"]', countryListClick);
    els.stateSelect.on('change', stateSelectChange);
    els.list.on('click', 'a[data-type="state"]', stateListClick);
    els.citySelect.on('change', citySelectChange);
    els.list.on('click', 'a[data-type="city"]', cityListClick);
    els.list.on('click', 'a[data-type="property"]', propertyListClick);
    els.select.on('change', showMap);
    els.list.on('click', 'a', showMap);
    els.list.on('click', '.directory-list-back', listBackClick);
    els.list.on('click', '.directory-list-show-video', showVideoClick);
    els.footer.find('a').on('click', showOverview);
    els.accordion.find('> dt > a').on('click', accordionToggle);
    els.component.on('click', '.directory_map', miniMapClick);
    els.closeFullScreenMap.on('click', closeFullScreenMap);
    els.component.on('click', '.property-details', propertyDetailsClick);
    els.list.on('click', '.reserve-cta', reserveCtaClick);
  };

  var regionSelectChange = function(){
    var val = $(this).val();
    var title = els.regionSelect.find('option[value="'+val+'"]').text();

    updateCountries(title, val, true);

    return false;
  }

  var regionListClick = function(e){
    e.preventDefault();

    var val = $(this).attr('data-key');
    var title = $(this).attr('data-value');

    els.regionSelect.val(val);

    updateCountries(title, val, true);

    return false;
  }

  var countrySelectChange = function(){
    var val = $(this).val();
    var title = els.countrySelect.find('option[value="'+val+'"]').text();

    if (hasStates(val)){
      updateStates(title, val, true);
    } else {
      updateCities(title, val, true);
    }

    return false;
  }

  var countryListClick = function(e){
    e.preventDefault();

    var val = $(this).attr('data-key');
    var title = $(this).attr('data-value');

    els.countrySelect.val(val);

    if (hasStates(val)){
      updateStates(title, val, true);
    } else {
      updateCities(title, val, true);
    }

    return false;
  }

  var stateSelectChange = function(){
    var val = $(this).val();
    var title = els.stateSelect.find('option[value="'+val+'"]').text();

    updateProperties(title, val, true, 'state');

    return false;
  }

  var stateListClick = function(e){
    e.preventDefault();

    var val = $(this).attr('data-key');
    var title = $(this).attr('data-value');

    els.stateSelect.val(val);

    updateProperties(title, val, true, 'state');

    return false;
  }

  var citySelectChange = function(){
    var val = $(this).val();
    var title = els.citySelect.find('option[value="'+val+'"]').text();

    updateProperties(title, val, true, 'city');

    return false;
  }

  var cityListClick = function(e){
    e.preventDefault();

    var val = $(this).attr('data-key');
    var title = $(this).attr('data-value');

    els.citySelect.val(val);

    updateProperties(title, val, true, 'city');

    return false;
  }

  var propertyListClick = function(e){
    e.preventDefault();

    var val = $(this).attr('data-key');

    if (vars.markers[val]){
      vars.map.setZoom(13);
    }

    showProperty(val);

    return false;
  }

  var showMap = function(e){

    if (vars.hotels) {
      els.component.addClass('show-map');
      if (els.video.length > 0) {
        videojs(els.video.find('.video-js').attr('id')).pause();
      }
    }

  }

  var listBackClick = function(e){
    e.preventDefault();

    els.component.removeClass('show-mini-map-property');
    els.html.removeClass('no-scroll');

    var val = "";
    var title = "";

    if (vars.selectedList.property){
      if (data.properties[vars.selectedList.property].state){
        val = data.properties[vars.selectedList.property].state;
        title = data.states[val].name;
        updateProperties(title, val, 'state')
      } else{
        val = data.properties[vars.selectedList.property].city;
        title = data.cities[val].name;
        updateProperties(title, val, 'city')
      };
    } else if (vars.selectedList.state || vars.selectedList.city){
      if (data.states[vars.selectedList.state]){
        val = data.states[vars.selectedList.state].country;
        title = data.countries[val].name;
        updateStates(title, val);
      } else if(data.cities[vars.selectedList.city]){
        val = data.cities[vars.selectedList.city].country;
        title = data.countries[val].name;
        updateCities(title, val);
      };
    } else if (vars.selectedList.country){
      val = data.countries[vars.selectedList.country].region;
      title = data.regions[val].name;
      updateCountries(title, val);
    } else if (vars.selectedList.region){
      updateRegions();
    };

    return false;
  }

  var showVideoClick = function(e){
    e.preventDefault();

    if (vars.hotels) {
      if (els.video.length > 0) {
        videojs(els.video.find('.video-js').attr('id')).play();
      }
      els.component.removeClass('show-map');
    }

    return false;
  };

  var showOverview = function () {
    els.overview.slideToggle();
    els.contentContainer.slideToggle();
    els.footer.find('a').toggleClass('open');
  };

  var accordionToggle = function (e) {
    e.preventDefault();

    // els.allPanels.slideUp();
    els.overview.find('.open').removeClass('open');

    if (!$(this).parent().next().is(":visible")) {
      $(this).parent().addClass('open').next().addClass('open');
    }

    els.allPanels.slideUp();
    els.overview.find('dd.open').slideDown();

    return false;
  }

  var miniMapClick = function () {
    if (els.component.hasClass('show-mini-map')) {
      for (var marker in vars.markers){
        vars.markers[marker].show = true;
      }
      showHideMarkers();
      els.component.removeClass('show-mini-map').addClass('show-fullscreen-map');
      els.html.addClass('no-scroll');
      setTimeout(function () {
        google.maps.event.trigger(vars.map, 'resize');
      },0);
    };
  };

  var showMiniMap = function () {
    els.component.addClass('show-mini-map');
    els.html.addClass('no-scroll');
    setTimeout(function () {
      google.maps.event.trigger(vars.map, 'resize');
    },0);
  };

  var closeFullScreenMap = function () {
    for (var marker in vars.markers){
      vars.markers[marker].show = false;
    }
    showHideMarkers();

    els.component.removeClass('show-fullscreen-map').addClass('show-mini-map');
    els.html.addClass('no-scroll');
  };

  var propertyDetailsClick = function () {
    if (els.component.hasClass('show-fullscreen-map')) {
      els.component.removeClass('show-fullscreen-map').addClass('show-mini-map');
      if (vars.isMobile) {
        els.html.addClass('no-scroll');
      }
    }
  };

  var reserveCtaClick = function () {
    var propId = $(this).attr('data-property-id');
    DS.booking().openBookingComponent(propId);
  };

  var resetSelects = function(){
    if (vars.selectedList.region == null){
      els.regionSelect.empty().append('<option value="default">'+vars.componentData.langSelectRegion+'</option>');
      els.countrySelect.prop('disabled', true).closest('.dropdown-component').addClass('disabled');
    } else {
      els.countrySelect.prop('disabled', false).closest('.dropdown-component').removeClass('disabled');
    };

    if (vars.selectedList.country == null){
      els.countrySelect.empty().append('<option value="default">'+vars.componentData.langSelectCountry+'</option>');
      els.stateSelect.prop('disabled', true).closest('.dropdown-component').addClass('disabled');
      els.citySelect.prop('disabled', true).closest('.dropdown-component').addClass('disabled');
    } else {
      els.stateSelect.prop('disabled', false).closest('.dropdown-component').removeClass('disabled');
      els.citySelect.prop('disabled', false).closest('.dropdown-component').removeClass('disabled');
    };

    if (vars.selectedList.state == null){
      els.stateSelect.empty().append('<option value="default">'+vars.componentData.langSelectState+'</option>');
    };

    if (vars.selectedList.city == null){
      els.citySelect.empty().append('<option value="default">'+vars.componentData.langSelectCity+'</option>');
    };
  };

  var updateRegions = function(){

    for (var marker in vars.markers){
      vars.markers[marker].show = true;
    }

    els.component.removeClass('show-mini-map');
    els.html.removeClass('no-scroll');

    showHideMarkers();

    vars.selectedList.region = null;
    vars.selectedList.country = null;
    vars.selectedList.state = null;
    vars.selectedList.city = null;
    vars.selectedList.property = null;
    resetSelects();

    var type = 'region',
      obj = data.regions,
      listHtml = '<div class="list-header"><h2>'+vars.componentData.langRegion+'</h2></div><ul>',
      selectHtml = '<option value="default">'+vars.componentData.langSelectRegion+'</option>';

    for (var key in obj){
      if (obj.hasOwnProperty(key)){
        var children = countChildren(key, data.countries, type);
        listHtml += '<li><a href="javascript:void(0);" data-type="'+type+'" data-key="'+key+'" data-value="'+obj[key]['name']+'">'+obj[key]['name']+'</a></li>';
        selectHtml += '<option value="'+key+'">'+obj[key]['name']+'</option>';
      }
    }
    listHtml += '</ul>';

    els.list.empty().append(listHtml);
    els.regionSelect.empty().append(selectHtml);

    if (data.regions["AFRICA"] && vars.spas) {
      els.bgImage.css('background-image', 'url('+data.regions["AFRICA"].image+')');
    };
  };

  var updateCountries = function(title, selectedRegionKey){

    for (var marker in vars.markers){
      markerPropertyID = vars.markers[marker].propertyID;
      markerRegion = data.properties[markerPropertyID].region;
      if ((markerRegion === selectedRegionKey || selectedRegionKey === 'default') && !vars.isMobile) {
        vars.markers[marker].show = true;
      } else {
        vars.markers[marker].show = false;
      }
    }
    showHideMarkers();


    if (selectedRegionKey === 'default') {
      resetMapView();
      return;
    }

    if (vars.isMobile) {
      showMiniMap();
    };

    if (vars.spas && selectedRegionKey) {
      $('.region-components').hide();
      $('.region-components[data-region-code="'+selectedRegionKey+'"]').show();
    }

    if (vars.hotels) {
      geocode(data.regions[selectedRegionKey].name);
    }

    if (vars.spas) {
      els.bgImage.css('background-image', 'url('+data.regions[selectedRegionKey].image+')');
    };

    vars.selectedList.region = selectedRegionKey;
    vars.selectedList.country = null;
    vars.selectedList.state = null;
    vars.selectedList.city = null;
    vars.selectedList.property = null;
    resetSelects();

    var type = 'country',
      obj = data.countries,
      count = 0,
      listHtml = '<div class="list-header">';
      listHtml += '  <a href="javascript:void(0);" class="directory-list-show-video directory-list-back" title="Back"></a>';
      listHtml += '  <div class="heading-container"><h2>'+title+'</h2></div>';
      listHtml += '</div>';
      listHtml += '<ul>';

    var selectHtml = '<option value="default">'+vars.componentData.langSelectCountry+'</option>';

    for (var key in obj){
      if (obj.hasOwnProperty(key) && obj[key]['region'] === selectedRegionKey){
        var children = countChildren(key, data.cities, type);
        count = count + children;
        listHtml += '<li><a href="javascript:void(0);" data-type="'+type+'" data-key="'+key+'" data-value="'+obj[key]['name']+'">'+obj[key]['name']+'</a></li>';
        selectHtml += '<option value="'+key+'">'+obj[key]['name']+'</option>';
      }
    }
    listHtml += '</ul>';

    els.list.empty().append(listHtml);
    els.countrySelect.empty().append(selectHtml);
    els.hotelCountSpan.text(count);
  };

  var updateStates = function(title, selectedCountryKey){

    if (selectedCountryKey === 'default') {
      return;
    }

    if (vars.isMobile) {
      showMiniMap();
    };

    for (var marker in vars.markers){
      markerPropertyID = vars.markers[marker].propertyID;
      markerCountry = data.properties[markerPropertyID].country;
      if (markerCountry === selectedCountryKey && !vars.isMobile) {
        vars.markers[marker].show = true;
      } else {
        vars.markers[marker].show = false;
      }
    }
    showHideMarkers();

    if (vars.hotels) {
      geocode(data.countries[selectedCountryKey].name);
    }

    if (vars.spas) {
      els.bgImage.css('background-image', 'url('+data.countries[selectedCountryKey].image+')');
    };
    vars.selectedList.country = selectedCountryKey;
    vars.selectedList.state = null;
    vars.selectedList.city = null;
    vars.selectedList.property = null;
    resetSelects();

    var type = 'state',
      obj = data.states,
      count = 0,
      listHtml = '<div class="list-header">';
      listHtml += '  <a href="javascript:void(0);" class="directory-list-back" title="Back"></a>';
      listHtml += '  <div class="heading-container"><h2>'+title+'</h2></div>';
      listHtml += '</div>';
      listHtml += '<ul>';

    var selectHtml = '<option value="default">'+vars.componentData.langSelectState+'</option>';

    for (var key in obj){
      if (obj.hasOwnProperty(key) && obj[key]['country'] === selectedCountryKey){
        var children = countChildren(key, data.properties, type);
        count = count + children;
        listHtml += '<li><a href="javascript:void(0);" data-type="'+type+'" data-key="'+key+'" data-value="'+obj[key]['name']+'">'+obj[key]['name']+'</a></li>';
        selectHtml += '<option value="'+key+'">'+obj[key]['name']+'</option>';
      }
    }
    listHtml += '</ul>';

    els.list.empty().append(listHtml);
    els.stateSelect.empty().append(selectHtml);
    els.hotelCountSpan.text(count);

    els.citySelect.closest('.dropdown-component').hide();
    els.stateSelect.closest('.dropdown-component').show();
  };

  var updateCities = function(title, selectedCountryKey){

    if (selectedCountryKey === 'default') {
      return;
    }

    if (vars.isMobile) {
      showMiniMap();
    };

    for (var marker in vars.markers){
      markerPropertyID = vars.markers[marker].propertyID;
      markerCountry = data.properties[markerPropertyID].country;
      if (markerCountry === selectedCountryKey && !vars.isMobile) {
        vars.markers[marker].show = true;
      } else {
        vars.markers[marker].show = false;
      }
    }
    showHideMarkers();

    if (vars.hotels) {
      geocode(data.countries[selectedCountryKey].name);
    }

    if (vars.spas) {
      els.bgImage.css('background-image', 'url('+data.countries[selectedCountryKey].image+')');
    };

    vars.selectedList.country = selectedCountryKey;
    vars.selectedList.state = null;
    vars.selectedList.city = null;
    vars.selectedList.property = null;
    resetSelects();

    var type = 'city',
      obj = data.cities,
      count = 0,
      listHtml = '<div class="list-header">';
      listHtml += '  <a href="javascript:void(0);" class="directory-list-back" title="Back"></a>';
      listHtml += '  <div class="heading-container"><h2>'+title+'</h2></div>';
      listHtml += '</div>';
      listHtml += '<ul>';
    var selectHtml = '<option value="default">'+vars.componentData.langSelectCity+'</option>';

    for (var key in obj){
      if (obj.hasOwnProperty(key) && obj[key]['country'] === selectedCountryKey){
        var children = countChildren(key, data.properties, type);
        count = count + children;
        listHtml += '<li><a href="javascript:void(0);" data-type="'+type+'" data-key="'+key+'" data-value="'+obj[key]['name']+'">'+obj[key]['name']+'</a></li>';
        selectHtml += '<option value="'+key+'">'+obj[key]['name']+'</option>';
      }
    }
    listHtml += '</ul>';

    els.list.empty().append(listHtml);
    els.citySelect.empty().append(selectHtml);
    els.hotelCountSpan.text(count);

    els.stateSelect.closest('.dropdown-component').hide();
    els.citySelect.closest('.dropdown-component').show();
  };

  var updateProperties = function(title, selectedCityStateKey, type){

    if (selectedCityStateKey === 'default') {
      return;
    }

    if (vars.isMobile) {
      showMiniMap();
    };

    for (var marker in vars.markers){
      markerPropertyID = vars.markers[marker].propertyID;
      markerCity = data.properties[markerPropertyID].city;
      markerState = data.properties[markerPropertyID].state;
      if ((markerCity === selectedCityStateKey || markerState === selectedCityStateKey) && !vars.isMobile) {
        vars.markers[marker].show = true;
      } else {
        vars.markers[marker].show = false;
      }
    }
    showHideMarkers();

    var cityStateName;
    if (type === 'state'){
      cityStateName = data.states[selectedCityStateKey].name;

      if (vars.spas) {
        els.bgImage.css('background-image', 'url('+data.states[selectedCityStateKey].image+')');
      };
    }

    if (type === 'city'){
      cityStateName = data.cities[selectedCityStateKey].name;

      if (vars.spas) {
        els.bgImage.css('background-image', 'url('+data.cities[selectedCityStateKey].image+')');
      };
    }

    if (vars.hotels) {
      geocode(cityStateName);
    }

    vars.selectedList.state = selectedCityStateKey;
    vars.selectedList.city = selectedCityStateKey;
    vars.selectedList.property = null;
    resetSelects();

    var type = 'property',
      obj = data.properties,
      count = 0,
      listHtml = '<div class="list-header">';
      listHtml += '  <a href="javascript:void(0);" class="directory-list-back" title="Back"></a>';
      listHtml += '  <div class="heading-container"><h2>'+title+'</h2></div>';
      listHtml += '</div>';
      listHtml += '<ul>';

    for (var key in obj){
      if (obj.hasOwnProperty(key) && (obj[key]['state'] === selectedCityStateKey || obj[key]['city'] === selectedCityStateKey)){
        count++;
        listHtml += '<li><a href="javascript:void(0);" data-type="'+type+'" data-key="'+key+'" data-value="'+obj[key]['name']+'">'+obj[key]['name']+'</a></li>';
      }
    }
    listHtml += '</ul>';

    els.list.empty().append(listHtml);
    els.hotelCountSpan.text(count);
  };

  var showProperty = function(propertyID){
    showHideMarkers();

    els.component.addClass('show-mini-map-property');
    if (vars.isMobile) {
      els.html.addClass('no-scroll');
    }

    vars.selectedList.property = propertyID;
    resetSelects();

    var prop = data.properties[propertyID];
    var streetAddress = '';
    if (prop.address && prop.address.length) {
      streetAddress = prop.address;
    };
    var city = '';
    if (prop.city && prop.city.length) {
      city = data.cities[prop.city].name;
    };
    var state = '';
    if (prop.state && prop.state.length) {
      state = ', '+data.states[prop.state].name;
    }
    var zip = '';
    if (prop.zipcode && prop.zipcode.length) {
      zip = ', '+prop.zipcode;
    };
    var country = '';
    if (prop.country && prop.country.length) {
      country = ', '+data.countries[prop.country].name;
    }
    var address = streetAddress+'<br />'+city+state+zip+country;

    var propertyHtml = '<div class="property-details" data-property-id="'+propertyID+'">';
    propertyHtml += '  <div id="prop-'+propertyID+'">';
    propertyHtml += '    <div class="list-header"><a href="javascript:void(0);" class="directory-list-back" title="Back"></a></div>';
    if (prop.logoImageOverride) {
      propertyHtml += '    <div class="logo-container"><div class="svg-logo" data-svgurl="'+prop.logoImageOverride+'" style="background-image: url('+prop.logoImageOverride+')"></div></div>';
    } else if(vars.componentData.brandLogo) {
      propertyHtml += '    <div class="logo-container"><div class="svg-logo" data-svgurl="'+vars.componentData.brandLogo+'" style="background-image: url('+vars.componentData.brandLogo+')"></div></div>';
    }
    if (prop.imageOverride) {
      propertyHtml += '    <div class="property-image" style="background-image: url('+prop.imageOverride+');"></div>';
    } else if (prop.image) {
      propertyHtml += '    <div class="property-image" style="background-image: url('+vars.starwoodImageUrl+prop.image+');"></div>';
    }

    // if prop status is N, don't link out to property
    if (prop.status == 'N') {
      propertyHtml += '    <h2>'+prop.name;
      if (prop.openingDate) {
        var openingDate = new Date(prop.openingDate);
        openingDate = Sugar.Date.medium(new Date('2023/05/01'))
        propertyHtml += ' - <br />'+vars.componentData.langOpening+' '+openingDate;
      }
      propertyHtml += '</h2>'

    } else{
      propertyHtml += '    <h2><a href="http://www.starwoodhotels.com/'+vars.componentData.brand+'/property/overview/index.html?propertyID='+propertyID+'&language='+vars.locale+'&localeCode='+vars.locale+'" target="_blank">'+prop.name+'</a></h2>';
    }
    propertyHtml += '    <hr />';
    propertyHtml += '    <div class="property-address">'+address+'</div>';
    propertyHtml += '    <div class="property-phone">'+vars.componentData.langPhone+': '+prop.phone+'</div>';

    // if prop status is N, don't link out to property
    if (prop.status !== 'N') {
      propertyHtml += '    <a href="javascript:void(0);" data-property-id="'+propertyID+'" aria-describedby="prop-'+propertyID+'" class="cta link reserve-cta">'+vars.componentData.langReserve+'</a>';
    }
    propertyHtml += '  </div>';
    propertyHtml += '</div>';
    els.list.empty().append(propertyHtml);

    if (vars.markers[propertyID]){
      var marker = vars.markers[propertyID];
      marker.setIcon(vars.componentData.activeMarker);

      vars.selectedMarker = {
        'marker': marker,
        'propertyID': marker.propertyID
      };

      vars.map.panTo(marker.getPosition());
    };

    if (vars.spas) {
      els.bgImage.css('background-image', 'url('+data.properties[propertyID].image+')');
    };
  }

  var hasStates = function(countryKey){
    var count = 0;
    for (var key in data.states){
      if (data.states.hasOwnProperty(key) && data.states[key]['country'] === countryKey){
        count++;
      }
    }

    if (count > 0){
      return true;
    } else{
      return false;
    }
  };

  // used to count how many children a location has
  // e.g. pass in the countries object to return how many are in a specific region
  // NAChildren = countChildren('NORTH_AMERICA', data.countries, 'region')
  var countChildren = function(parentKey, childrenObj, parentType){
    var count = 0;

    for (var key in childrenObj){
      if (childrenObj.hasOwnProperty(key)){
        if (childrenObj[key][parentType] === parentKey){
          count++;
        }
      }
    }

    return count;
  };

  var initMap =  function(){
    if ( els.map.length < 1 ){
      return;
    }

    vars.mapInitialized = true;
    vars.geocoder = new google.maps.Geocoder();

    vars.map = new google.maps.Map(els.map[0], {
      center: vars.defaultCenter,
      zoom: vars.defaultZoom,
      styles: vars.styles,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP
      },
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      scaleControl: false
    });

    setMarkers(vars.map);

    // var markerCluster = new MarkerClusterer(map, vars.markers, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

    google.maps.event.addListenerOnce(vars.map, 'idle', function(){
       els.mapView.addClass('map-loaded');
    });
  };

  var setMarkers = function(map){
    var markerWidth = vars.componentData.pinWidth;
    var markerHeight = vars.componentData.pinHeight;

    var image = {
      url: vars.componentData.defaultMarker,
      size: new google.maps.Size(markerWidth, markerHeight),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point((markerWidth/2), (markerHeight/2))
    };

    var i = 0;
    for (var property in data.properties){
      if (data.properties.hasOwnProperty(property) && data.properties[property].latitude && data.properties[property].longitude){

        var propertyID = property;
        var property = data.properties[property];

        var marker = new google.maps.Marker({
          position: {lat: parseFloat(property.latitude), lng: parseFloat(property.longitude)},
          map: map,
          icon: image,
          title: property.name,
          zIndex: i,
          propertyID: propertyID,
          show: true
        });

        google.maps.event.addListener(marker, 'click', function(e){
          showProperty(this.propertyID);
        });

        google.maps.event.addListener(marker, 'mouseover', function(e){
          // switch icon for larger version
          this.setIcon(vars.componentData.activeMarker);
        });

        google.maps.event.addListener(marker, 'mouseout', function(e){
          if (vars.selectedMarker.propertyID !== this.propertyID){
            // switch icon for smaller version
            this.setIcon(vars.componentData.defaultMarker);
          }
        });

        vars.markers[propertyID] = marker;

        i++;
      }
    }
  };

  var showHideMarkers = function () {
    for (var marker in vars.markers){
      vars.markers[marker].setIcon(vars.componentData.defaultMarker);
      if (vars.markers[marker].show === true) {
        vars.markers[marker].setMap(vars.map);
      } else{
        vars.markers[marker].setMap(null);
      };
    }
  };

  var resetMapView = function () {
    vars.map.panTo(vars.defaultCenter);
    vars.map.setZoom(vars.defaultZoom);
  }

  var geocode = function(address){
    if (address === 'Asia Pacific'){
      // geocode can't find 'Asia Pacific' for some reason
      address = 'Asia';
    };
    vars.geocoder.geocode( { 'address': address}, function(results, status){
      if (status == google.maps.GeocoderStatus.OK){
        vars.map.setCenter(results[0].geometry.location);
        vars.map.fitBounds(results[0].geometry.viewport);
      }
    });
  };

  setOverviewColumns = function () {
    els.accordion.find('.country-container').each(function () {
      var widths = $(this).find('> div').map(function () {
          return $(this).outerWidth();
      }).get();
      var maxWidth = Math.max.apply(null, widths);
      maxWidth = Math.min(250, maxWidth);

      $(this).css({
          "-webkit-column-width": maxWidth,
          "column-width": maxWidth
      });
    });

    els.allPanels.hide();
  };

  return {
    init: function(el){
      var $el = $(el);
      els = {
        component: $el,
        html: $('html'),
        body: $('body'),
        contentContainer: $el.find('.directory-content'),
        listView: $el.find('.directory-content__list-view'),
        mapView: $el.find('.directory-content__map-view'),
        list: $el.find('.list-component'),
        listRegion: $el.find('.directory-content__list a[data-type="region"]'),
        regionSelect: $el.find('.directory-region-select'),
        countrySelect: $el.find('.directory-country-select'),
        stateSelect: $el.find('.directory-state-select'),
        citySelect: $el.find('.directory-city-select'),
        map: $el.find('.directory_map'),
        select: $el.find('.select-container select'),
        video: $el.find('.directory-background-video'),
        bgImage: $el.find('.directory-background-image'),
        accordion: $el.find('.accordion'),
        allPanels: $el.find('.accordion > dd'),
        overview: $el.find('.directory-overview'),
        footer: $el.find('.directory-footer'),
        miniMap: $el.find('.mini-map'),
        hotelCountSpan: $el.find('.hotel-count span'),
        closeFullScreenMap: $el.find('.close-full-screen-map'),
        reserveCta: $el.find('.reserve-cta')
      };

      vars = {
        locale: els.body.attr('data-locale'),
        lang: lang = $('html')[0].lang,
        componentData: els.component.data(),
        isMobile: window.app.isMobile(),
        starwoodImageUrl: 'http://www.starwoodhotels.com/',
        mapInitialized: false,
        hotels: false,
        spas: false,
        markers: {},
        defaultZoom: 3,
        defaultCenter: {lat: 25, lng: 13},
        selectedList: {
          region: null,
          country: null,
          state: null,
          city: null,
          property: null
        },
        selectedMarker: {},
        styles: window.mapStyle
      };

      Sugar.Date.setLocale(vars.lang);

      if (els.component.hasClass('directory-component--hotels')) {
        vars.hotels = true;
        data = window.MARRIOTT_DIRECTORY_DATA;
      };

      if (els.component.hasClass('directory-component--spas')) {
        vars.spas = true;
        data = window.MARRIOTT_SPA_GEO_DATA;
      };

      els.citySelect.closest('.dropdown-component').hide();

      updateRegions();
      setOverviewColumns();
      els.overview.hide();
      listen();

      if ( els.map.length > 0 && window.app.mapReady()  && !vars.mapInitialized && vars.hotels){
        initMap();
      }
    }
  };
};

$(function(){
  if ( $('.directory-component').length > 0 ){
    $('.directory-component').each(function(i,el){
      var directory = new DS.directory();
      directory.init(el);
    });
  }
});
