var DS = window.DS || {};

DS.verticalGridBlockGallery = function (el) {
  var els = {};
  var $document = $(document);

  var init = function () {
    els.component = $(el);
    els.modal = els.component.find('.vertical-grid-block__modal');
    els.openModalButtons = els.component.find('.action-open-modal');
    els.closeModalButtons = els.component.find('.action-close-modal');
    els.previousButtons = els.component.find('.action-previous');
    els.nextButtons = els.component.find('.action-next');
    els.imageItems = els.component.find('.vertical-grid-block__modal__image-item');
  };

  var open = function () {
    els.modal.addClass('vertical-grid-block__modal--state-open');
    els.modal.removeAttr('aria-hidden');
  };

  var close = function () {
    els.modal.removeClass('vertical-grid-block__modal--state-open');
    els.modal.attr('aria-hidden', true);
  };

  var previous = function () {
    var $activeElement = els.imageItems.filter('.vertical-grid-block__modal__image-item--active');
    els.imageItems.removeClass('vertical-grid-block__modal__image-item--active');
    els.imageItems.attr('aria-hidden', true);
    var $previousItem = $activeElement.is(':first-child')
      ? els.imageItems.last()
      : $activeElement.prev();
    $previousItem.addClass('vertical-grid-block__modal__image-item--active');
    $previousItem.removeAttr('aria-hidden');
    $previousItem.focus();
  };

  var next = function () {
    var $activeElement = els.imageItems.filter('.vertical-grid-block__modal__image-item--active');
    els.imageItems.removeClass('vertical-grid-block__modal__image-item--active');
    els.imageItems.attr('aria-hidden', true);
    var $nextItem = $activeElement.is(':last-child')
      ? els.imageItems.first()
      : $activeElement.next();
    $nextItem.addClass('vertical-grid-block__modal__image-item--active');
    $nextItem.removeAttr('aria-hidden');
    $nextItem.focus();
  };

  var addListeners = function () {
    $document.on('keyup', function (event) {
      var code = (event.keyCode ? event.keyCode : event.which);
      if (code === 27) open();
    });

    els.openModalButtons.click(function () {
      open();
    });

    els.closeModalButtons.click(function () {
      close();
    });

    els.previousButtons.click(function () {
      previous();
    });

    els.nextButtons.click(function () {
      next();
    });
  };

  init();
  addListeners();
};

DS.verticalGrid = function (el) {
  var els = {};

  var init = function () {
    els.component = $(el);
    els.blockList = els.component.find('.vertical-grid__block-list');
    els.filterItems = els.component.find('.vertical-grid__filter-item');

    els.component.find('.vertical-grid-block--gallery').each(function (i, el) {
      new DS.verticalGridBlockGallery(el);
    });

    els.blockList.isotope({
      itemSelector: '.vertical-grid-block',
      layoutMode: 'spineAlign',
      transitionDuration: 0
    });

    els.blockList.imagesLoaded().progress(function () {
      els.blockList.isotope();
    });

    els.filterItems.click(function (event) {
      event.preventDefault();
      var $currentTarget = $(this);
      var filterValue = $currentTarget.data('filterValue').toLowerCase();
      els.filterItems.removeClass('vertical-grid__filter-item--state-active');
      $currentTarget.addClass('vertical-grid__filter-item--state-active');
      if (filterValue === 'all') {
        els.blockList.isotope({filter: '*'});
      } else {
        els.blockList.isotope({filter: '[data-block-type=' + filterValue + ']'});
      }
    });
  };

  init();
};

$(function () {
  if (!$('.vertical-grid').length) return;
  $('.vertical-grid').each(function (i, el) {
    new DS.verticalGrid(el);
  });
});
