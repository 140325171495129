var DS = window.DS || {};
DS.eventCalendar = function (el) {
  var els = {};

  var filteredEvents = [];

  var clndr = undefined;

  var init = function () {
    els.component = $(el);
    els.window = $(window);
    els.views = els.component.find('.event-calendar-component-view');
    els.filterViewControls = els.component.find('.event-filters__view-control');
    els.calendar = els.component.find('.calendar');
    els.template = els.component.find('#template-calendar');
    els.loadMore = els.component.find('.event-calendar-gallery__load-more');
    els.galleryView = els.component.find('.event-calendar-gallery');
    els.galleryViewEventList = els.component.find('.event-calendar-gallery__event-list');
    els.eventFilterForm = els.component.find('.event-filter-form');
    els.filterInputCity = els.eventFilterForm.find("input[name='city']");
    els.filterInputHotel = els.eventFilterForm.find("input[name='hotel']");
    els.filterInputArtist = els.eventFilterForm.find("input[name='artist']");
    els.filterInputArrivalDate = els.eventFilterForm.find("input[name='arrivalDate']");
    els.filterInputDepartureDate = els.eventFilterForm.find("input[name='departureDate']");

    if (els.calendar.length) {
      initCalendar();
    }

    els.galleryViewEventList.isotope({
      itemSelector: '.event-card',
      percentPosition: true,
      transitionDuration: 0
    });

    els.galleryViewEventList.imagesLoaded().progress(function () {
      els.galleryViewEventList.isotope();
    });

    listen();
  };

  var listen = function () {
    els.loadMore.on('click', function (event) {
      els.galleryView.toggleClass('event-calendar-gallery--state-load-more');
    });

    els.filterViewControls.on('click', function (event) {
      var view = $(this).data('view');
      els.filterViewControls.removeClass('event-filters__view-control--active');
      $(this).addClass('event-filters__view-control--active');
      els.component
        .removeClass (function (index, className) {
          return (className.match (/(^|\s)event-calendar-component--state-\S+/g) || []).join(' ');
        })
        .addClass('event-calendar-component--state-' + view);
      els.views.attr('aria-hidden',  'true');
      els.views.filter('[data-view="' + view + '"]').removeAttr('aria-hidden');
    });
  };

  var calendarListeners = function () {
    els.filterInputCity.on('change',function(){
      filterEvents();
    })
    els.filterInputHotel.on('change',function(){
      filterEvents();
    })
    els.filterInputArtist.on('change',function(){
      filterEvents();
    })
    els.filterInputArrivalDate.on('change',function(){
      filterEvents();
    })
    els.filterInputDepartureDate.on('change',function(){
      filterEvents();
    })
  };

  var checkIfEmpty = function () {
    if (!$('.day.event:visible')[0]) {
      $('.no-events').addClass('show');
    } else {
      $('.no-events').removeClass('show');
    }
  };

  var filterEvents = function () {
    var filterSettings = {};
    var out;

    if (els.filterInputArtist.val()) {
      filterSettings.artist_ID = parseInt(els.filterInputArtist.val());
    }
    if (els.filterInputCity.val()) {
      filterSettings.city_ID = parseInt(els.filterInputCity.val());
    }
    if (els.filterInputHotel.val()) {
      filterSettings.property_ID = parseInt(els.filterInputHotel.val());
    }
    // filter once before date filtering
    out = _.where(window.MARRIOTT_EVENT_DATA, filterSettings) ;

    if (els.filterInputArrivalDate.val()) {
      filterSettings.arrivalDate = parseInt(els.filterInputArrivalDate.val());
      out =  _.filter(out, function(event){
        return filterSettings.arrivalDate <= event.timestamp;
      });
    }

    if (els.filterInputDepartureDate.val()) {
      filterSettings.departureDate = parseInt(els.filterInputDepartureDate.val());
      out =  _.filter(out, function(event){
        return filterSettings.departureDate >= event.timestamp;
      });
    }

    clndr.setEvents(out);
  };

  var initCalendar = function () {
    clndr =  els.calendar.clndr({
      events: window.MARRIOTT_EVENT_DATA,
      template: $('#template-calendar').html(),
      daysOfTheWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      multiDayEvents: {
        endDate: 'end',
        singleDay: 'date',
        startDate: 'start'
      },
      clickEvents: {
        onMonthChange: function (month) {
          checkIfEmpty();
        }
      },
      ready: function () {
        //timeout because clndr's ready function is not reliable
        setTimeout(function(){
          checkIfEmpty();
          calendarListeners();
        }, 0);
      }
    });
  };

  init();
};

$(function () {
  var $components = $('.event-calendar-component');
  if (!$components.length) return;
  $components.each(function (i, el) {
    new DS.eventCalendar(el);
  });
});
