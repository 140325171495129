var DS = window.DS || {};
DS.app = function() {
  var els = {};
  var vars = {};

  var addTabIndexes = function() {
    $('h1,h2,h3,h4,h5,h6,h7,h8').attr('tabindex','0');
  };

  var listen = function() {
    els.window.on('resize',resize);
  };

  var resize = function() {
    var newScreenWidth = els.window.innerWidth();
    if(vars.currScreenWidth < vars.desktopBreakpoint && newScreenWidth >= vars.desktopBreakpoint
    || vars.currScreenWidth >= vars.desktopBreakpoint && newScreenWidth < vars.desktopBreakpoint) {
      reloadPage();
    }
    vars.currScreenWidth = newScreenWidth;
  };

  var reloadPage = function() {
    window.location.reload();
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        window: $(window)
      };

      vars = {
        mapReady: false,
        currScreenWidth: els.window.innerWidth(),
        desktopBreakpoint: 769
      };

      $(function() {
        addTabIndexes();
        listen();
      });
    },

    map: function() {
      vars.mapReady = true;
      els.window.trigger('googleMapsReady');
    },

    mapReady: function () {
      return vars.mapReady;
    },

    isMobile: function() {
      return /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || !(window.matchMedia("(min-width: " + vars.desktopBreakpoint + "px)").matches);
    }
  };
};

window.app = new DS.app();
app.init();
