var DS = window.DS || {};
DS.grid = function() {
  var els = {};

  var listen = function() {
    els.loadMore.on('click', function(){

      var hiddenBlocks = els.component.find('.grid-block-component.hide');

      hiddenBlocks.removeClass('hide');
      els.loadMore.hide();


    })
  };

  var initIsotope = function() {
    els.component.isotope({
      itemSelector: '.grid-block-component',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer'
      },
      transitionDuration: 0
    })

    els.component.imagesLoaded().progress(function () {
      els.component.isotope();
    });
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        loadMore : $el.find('.grid-component__controls .action-show')
      };
      if (!els.component.hasClass('no-isotope')) {
        initIsotope();
      }
      listen();
    }
  };
};

$(function() {
  $(".grid-component").each(function(i,el) {
    var grid = new DS.grid();
    grid.init(el);
  });
});
